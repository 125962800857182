<template>
  <div class="container pr-2 my-4">
    <h1 class="text-3xl font-extrabold my-10 px-4">Configurações</h1>

    <div class="flex">
      <div class="w-1/5 flex-shrink-0">
        <nav class="rounded shadow border border-gray-300">
          <router-link
            to="/gerenciador/configuracoes/linguas"
            class="block px-4 py-2 font-bold hover:bg-gray-100 hover:text-blue-600 text-gray-700 border-b border-gray-300"
            >Línguas</router-link
          >
          <router-link
            to="/gerenciador/configuracoes/periodos"
            class="block px-4 py-2 font-bold hover:bg-gray-100 hover:text-blue-600 text-gray-700 border-b border-gray-300"
            >Períodos</router-link
          >
          <router-link
            to="/gerenciador/configuracoes/fases"
            class="block px-4 py-2 font-bold hover:bg-gray-100 hover:text-blue-600 text-gray-700 border-b border-gray-300"
            >Fases</router-link
          >
          <router-link
            to="/gerenciador/configuracoes/correlatos"
            class="block px-4 py-2 font-bold hover:bg-gray-100 hover:text-blue-600 text-gray-700 border-b border-gray-300"
            >Correlatos</router-link
          >
        </nav>
      </div>

      <div class="bg-white p-6 rounded shadow ml-4 w-full">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Settings",
  components: {},
  filters: {},
  props: {},
  data: function() {
    return {
      loadingLanguage: false,
      newLangTitle: "",
      newLangAbbrev: ""
    };
  },
  computed: {
    ...mapState({
      languages: state => state.Settings.languages,
      maintenance: state => state.Settings.maintenance
    })
    // ...mapGetters({
    //   item: "namespace/item"
    // })
  },
  watch: {},
  async created() {
    await this.$store.dispatch("Settings/list");
  },
  async mounted() {},
  methods: {
    async addLanguage() {
      const languages = [...this.languages];
      languages.push({
        abbreviation: this.newLangAbbrev,
        title: this.newLangTitle
      });

      const response = await this.$store.dispatch("Settings/update", [
        "languages",
        {
          languages: [...languages]
        }
      ]);

      if (response) {
        await this.$store.dispatch("Settings/list");
        this.newLangTitle = "";
        this.newLangAbbrev = "";
      }
    },

    async remove(index) {
      let languages = [...this.languages];
      languages.splice(index, 1);

      const response = await this.$store.dispatch("Settings/update", [
        "languages",
        {
          languages: [...languages]
        }
      ]);

      if (response) {
        await this.$store.dispatch("Settings/list");
        this.newLangTitle = "";
        this.newLangAbbrev = "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.router-link-exact-active {
  background-color: #fff;
  color: rgb(66, 153, 225);
}
</style>
